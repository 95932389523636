import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatOptionModule } from '@angular/material/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatRadioChange, MatRadioModule } from '@angular/material/radio'
import { MatTooltipModule } from '@angular/material/tooltip'
import { LetDirective } from '@ngrx/component'
import { Store, select } from '@ngrx/store'
import { AccountObject, Cost, CreditBalance, CreditPool, DealPartyE, DealPaymentTerms, Estimated, ForexData, LocationObject, MatchedOffer, Measure, Note, ShipmentRate, User } from '@tradecafe/types/core'
import { DeepReadonly, getHsCode, printPaymentTerms } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { includes, isArray, isEqual, range, round, sortBy } from 'lodash-es'
import { Observable, ReplaySubject, asapScheduler, combineLatest, from, of } from 'rxjs'
import { distinctUntilChanged, map, observeOn, skip, switchMap, take, tap, withLatestFrom } from 'rxjs/operators'
import { GenericOption, loadAccounts, selectAccountEntities } from 'src/app/store/accounts'
import { loadCountries, selectAllCountries } from 'src/app/store/countries'
import { loadCurrencies, selectAllCurrencies } from 'src/app/store/currencies'
import { loadItemTypes, selectAllItemTypes } from 'src/app/store/item-types'
import { loadLocations, selectAllLocations } from 'src/app/store/locations'
import { loadMeasures, selectAllMeasures } from 'src/app/store/measures'
import { loadPackageTypes, selectAllPackageTypes } from 'src/app/store/package-types'
import { loadPricingTerms, selectAllPricingTerms } from 'src/app/store/pricing-terms'
import { loadUnlocodes, selectAllUnlocodes } from 'src/app/store/unlocodes'
import { loadUsers, selectCoordinatorsOptions, selectTradersOptions, selectUserEntities } from 'src/app/store/users'
import { loadWeightTypes, selectAllWeightTypes } from 'src/app/store/weight-types'
import { loadWrappingTypes, selectAllWrappingTypes } from 'src/app/store/wrapping-types'
import { AddressFieldModule } from 'src/components/address-field/address-field.module'
import { AddressPickerOptions } from 'src/components/address-picker/address-picker.component'
import { CostsListModule } from 'src/components/costs-list/costs-list.module'
import { CreditBalanceComponent } from 'src/components/credit-balance/credit-balance.component'
import { EpochFieldModule } from 'src/components/epoch/epoch-field/epoch-field.module'
import { EpochRangeFieldModule } from 'src/components/epoch/epoch-range-field/epoch-range-field.module'
import { ProductFieldModule } from 'src/components/product-field/product-field.module'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchComponent } from 'src/components/select-search/select-search.component'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { environment } from 'src/environments/environment'
import { MeasurePipe } from 'src/filters/measure.pipe'
import { CostsFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { getPartyUsersMO } from 'src/services/data/accounts.service'
import { CreditPoolService } from 'src/services/data/credit-pool.service'
import { MeasuresService } from 'src/services/data/measures.service'
import { SPECIAL_INSTRUCTIONS } from 'src/services/data/notes.service'
import { dayjs } from 'src/services/dayjs'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { disableIf } from 'src/shared/utils/disable-if'
import { replayForm } from 'src/shared/utils/replay-form'
import { selectOptions } from 'src/shared/utils/select-options'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { NoteFormService } from '../../../../../components/notes/note-form/note-form.service'
import { FinanceService } from '../finance.service'
import { MatchedOfferFormGroup, MatchedOfferFormService, MatchedOfferFormValue } from '../matched-offer-form.service'

@Component({
  selector: 'tc-matched-offer-details',
  templateUrl: './matched-offer-details.component.html',
  styleUrls: ['./matched-offer-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatTooltipModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
    FormsModule,
    SelectSearchModule,
    FlexLayoutModule,
    EpochRangeFieldModule,
    CreditBalanceComponent,
    ProductFieldModule,
    AddressFieldModule,
    PipesModule,
    CostsListModule,
    EpochFieldModule,
    LetDirective,
  ],
})
export class MatchedOfferDetailsComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private readonly store: Store,
    private readonly Measures: MeasuresService,
    private readonly CreditPool: CreditPoolService,
    private readonly Finance: FinanceService,
    private readonly NoteForm: NoteFormService,
    private readonly measure: MeasurePipe,
    private readonly currency: CurrencyPipe,
    private readonly MatchedOfferForm: MatchedOfferFormService,
    private readonly decimalPipe: DecimalPipe,
  ) {
    super();
    this.enableRouteService = environment.enableRouteService ?? false;
  }

  @Input() matchedOffer: DeepReadonly<MatchedOffer>
  @Input() isAutomatedOffer: boolean
  @Input() moForm: MatchedOfferFormGroup
  @Input() costsForm: CostsFormGroup
  @Input() fxRates: ForexData
  @Input() shipmentRates$: Observable<DeepReadonly<Dictionary<ShipmentRate>>>
  @Output() updateCost = new EventEmitter<DeepReadonly<Partial<Cost>>>()
  @Output() removeCost = new EventEmitter<DeepReadonly<Partial<Cost>>>()

  protected readonly enableRouteService: boolean = false;
  @ViewChild('originPort') pickupPortElement: SelectSearchComponent<boolean>;
  @ViewChild('destPort') destPortElement: SelectSearchComponent<boolean>;

  protected supplierName$: Observable<string>
  protected buyerName$: Observable<string>
  protected hsCode$: Observable<string>
  private buyer$: Observable<DeepReadonly<AccountObject>>
  protected supplierAvgDaysToDue$: Observable<number>
  protected buyerAvgDaysToDue$: Observable<number>
  protected readonly balance$ = new ReplaySubject<CreditBalance>(1)
  protected readonly supplierCreditPool$ = new ReplaySubject<CreditPool>(1)
  protected readonly buyerCreditPool$ = new ReplaySubject<CreditPool>(1)
  private readonly supplierPaymentTerms$ = new ReplaySubject<DeepReadonly<DealPaymentTerms>>(1)
  private readonly buyerPaymentTerms$ = new ReplaySubject<DeepReadonly<DealPaymentTerms>>(1)
  protected readonly supplierPaymentTermsStr$ = this.supplierPaymentTerms$.pipe(map(pt => printPaymentTerms(pt)))
  protected readonly buyerPaymentTermsStr$ = this.buyerPaymentTerms$.pipe(map(pt => printPaymentTerms(pt)))
  protected hasSupplierInstructions$: Observable<DeepReadonly<Note>>
  protected hasBuyerInstructions$: Observable<DeepReadonly<Note>>
  protected supplierIndWeight$: Observable<string>
  protected supplierIndPrice$: Observable<string>
  protected buyerIndWeight$: Observable<string>
  protected buyerIndPrice$: Observable<string>
  // totals
  private readonly totals$ = new ReplaySubject<Estimated>(1)
  protected readonly buyTotalPrice$ = this.totals$.pipe(map(t => t.buy_price))
  protected readonly buyTotalPriceCad$ = this.totals$.pipe(map(t => t.buy_price_cad))
  protected readonly sellTotalPrice$ = this.totals$.pipe(map(t => t.sell_price))
  protected readonly sellTotalPriceCad$ = this.totals$.pipe(map(t => t.sell_price_cad))
  protected readonly rawCost$ = this.totals$.pipe(map(t => t.raw_cost ))
  protected readonly targetCost$ = this.totals$.pipe(map(t => t.target_cost ))
  public readonly revenueCad$ = this.totals$.pipe(map(t => t.revenue ))
  public readonly marginCad$ = this.totals$.pipe(map(t => t.margin ))
  public readonly margin$ = this.totals$.pipe(map(t =>
    t.margin_p ? `${this.decimalPipe.transform(t.margin_p * 100, '1.2-2')}%` : '—'))

  // ref data
  protected readonly TODAY = dayjs().utc().startOf('day').unix()
  protected readonly weights = range(5000, 26501, 500)
  protected readonly countries$ = this.store.pipe(select(selectAllCountries), waitNotEmpty(), map(c => sortBy(c, 'name')))
  protected readonly currencies$ = this.store.pipe(select(selectAllCurrencies), waitNotEmpty())
  protected readonly itemTypes$ = this.store.pipe(select(selectAllItemTypes), waitNotEmpty())
  protected readonly locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())
  protected readonly unlocodes$ = this.store.pipe(select(selectAllUnlocodes), waitNotEmpty())
  protected readonly measures$ = this.store.pipe(select(selectAllMeasures), waitNotEmpty())
  protected readonly packageTypes$ = this.store.pipe(select(selectAllPackageTypes), waitNotEmpty())
  protected readonly pricingTerms$ = this.store.pipe(select(selectAllPricingTerms), waitNotEmpty())
  protected readonly weightTypes$ = this.store.pipe(select(selectAllWeightTypes), waitNotEmpty())
  protected readonly wrappings$ = this.store.pipe(select(selectAllWrappingTypes), waitNotEmpty())
  protected readonly accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())

  // address picker options
  protected establishmentAddressOptions$: Observable<DeepReadonly<AddressPickerOptions>>
  protected invoiceAddressOptions$: Observable<DeepReadonly<AddressPickerOptions>>

  // user options
  private users$ = this.store.pipe(select(selectUserEntities), waitNotEmpty())
  protected buyerTraders$: Observable<GenericOption[]>
  protected buyerUsers$: Observable<DeepReadonly<User[]>>
  protected coordinators$: Observable<GenericOption[]>
  protected supplierTraders$: Observable<GenericOption[]>
  protected supplierUsers$: Observable<DeepReadonly<User[]>>

  ngOnInit() {
    this.store.dispatch(loadCountries())
    this.store.dispatch(loadCurrencies({}))
    this.store.dispatch(loadItemTypes())
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadMeasures())
    this.store.dispatch(loadPackageTypes())
    this.store.dispatch(loadPricingTerms({}))
    this.store.dispatch(loadWeightTypes())
    this.store.dispatch(loadWrappingTypes())
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadUsers({}))
    this.store.dispatch(loadUnlocodes())


    // address picker options
    this.establishmentAddressOptions$ = replayForm(this.moForm).pipe(map(mo => ({
      accountId: this.matchedOffer.offer.account,
      addresses: mo.establishments,
      title: 'Select Establishment',
      showEstablishment: true,
      allowMultiple: true,
      limit: 3,
    })))
    this.invoiceAddressOptions$ = replayForm(this.moForm).pipe(map(mo => ({
      title: 'Select Invoice Address',
      accountId: this.matchedOffer.bid.account,
      address: mo.invoiceAddress,
    })))

    // user options
    this.coordinators$ = selectOptions(this.store, selectCoordinatorsOptions, this.moForm.controls.logisticsUserId)
    this.buyerTraders$ = selectOptions(this.store, selectTradersOptions, this.moForm.controls.buyerTraderId)
    this.supplierTraders$ = selectOptions(this.store, selectTradersOptions, this.moForm.controls.supplierTraderId)
    this.supplierUsers$ = this.users$.pipe(map(users => getPartyUsersMO(users, this.matchedOffer.offer.account)))
    this.buyerUsers$ = this.users$.pipe(map(users => getPartyUsersMO(users, this.matchedOffer.bid.account)))

    const supplierId = this.matchedOffer.offer.account
    const buyerId = this.matchedOffer.bid.account
    const supplier$ = this.accounts$.pipe(map(accounts => accounts[supplierId]))
    this.buyer$ = this.accounts$.pipe(map(accounts => accounts[buyerId]))
    this.supplierName$ = supplier$.pipe(map(acc => acc.name))
    this.buyerName$ = this.buyer$.pipe(map(acc => acc.name))
    this.supplierAvgDaysToDue$ = supplier$.pipe(map(acc => acc.attributes.credit_info?.avg_days_to_due_date))
    this.buyerAvgDaysToDue$ = this.buyer$.pipe(map(acc => acc.attributes.credit_info?.avg_days_to_due_date))
    this.hasBuyerInstructions$ = replayForm(this.moForm).pipe(map(mo => this.hasBuyerInstructions(mo)))
    this.hasSupplierInstructions$ = replayForm(this.moForm).pipe(map(mo => this.hasSupplierInstructions(mo)))

    this.hsCode$ = combineLatest([
      this.buyer$,
      replayForm(this.moForm.controls.productId),
      replayForm(this.moForm.controls.itemTypeId),
    ]).pipe(map(([buyer, product_id, item_type_id]) =>
      getHsCode(buyer, { product_id, item_type_id }) || ''))

    this.supplierIndWeight$ = replayForm(this.moForm).pipe(map(({ supplierEstWeight, supplierMeasureId }) =>
        supplierEstWeight || supplierEstWeight === 0
          ? this.measure.transform(supplierEstWeight, supplierMeasureId)
          : ''))
    this.buyerIndWeight$ = replayForm(this.moForm).pipe(map(({ buyerEstWeight, buyerMeasureId }) =>
        buyerEstWeight || buyerEstWeight === 0
          ? this.measure.transform(buyerEstWeight, buyerMeasureId)
          : ''))
    this.supplierIndPrice$ = replayForm(this.moForm).pipe(map(({ supplierEstPrice, supplierCurrencyCode, supplierMeasureId }) =>
      supplierEstPrice && supplierCurrencyCode && supplierMeasureId
        ? this.measure.transform(
            this.currency.transform(supplierEstPrice, supplierCurrencyCode, 'symbol-narrow', '1.4'/* , 'no_TH' */),
            supplierMeasureId)
        : ''))
    this.buyerIndPrice$ = replayForm(this.moForm).pipe(map(({ buyerEstPrice, buyerCurrencyCode, buyerMeasureId }) =>
      buyerEstPrice && buyerCurrencyCode && buyerMeasureId
        ? this.measure.transform(
            this.currency.transform(buyerEstPrice, buyerCurrencyCode, 'symbol-narrow', '1.4'/* , 'no_TH' */),
            buyerMeasureId)
        : ''))

    combineLatest([
      combineLatest([this.supplierCreditPool$, replayForm(this.moForm.controls.supplierTraderId)]).pipe(
        switchMap(([creditPool, traderId]) => from(this.CreditPool.readPaymentTerms(creditPool, DealPartyE.supplier, traderId))),
        tap(paymentTerms => this.supplierPaymentTerms$.next(paymentTerms))),
      combineLatest([this.buyerCreditPool$, replayForm(this.moForm.controls.buyerTraderId)]).pipe(
        switchMap(([creditPool, traderId]) => from(this.CreditPool.readPaymentTerms(creditPool, DealPartyE.buyer, traderId))),
        tap(paymentTerms => this.buyerPaymentTerms$.next(paymentTerms))),
    ]).pipe(untilComponentDestroyed(this)).subscribe()

    combineLatest([this.supplierPaymentTerms$, this.buyerPaymentTerms$, this.buyer$]).pipe(take(1))
    .subscribe(([supplierPaymentTerms, buyerPaymentTerms, buyer]) => {
      this.calculateTermDate({ buyer, supplierPaymentTerms, buyerPaymentTerms })
    })

    disableIf(this.moForm.controls.originIsPort, !this.enableRouteService);
    disableIf(this.moForm.controls.destIsPort, !this.enableRouteService);

    // fetch credit pools & balance
    this.CreditPool.getFor(supplierId).then(supplierCreditPool => this.supplierCreditPool$.next(supplierCreditPool))
    this.CreditPool.getFor(buyerId).then(buyerCreditPool => this.buyerCreditPool$.next(buyerCreditPool))
    this.CreditPool.getBalance(buyerId).then(balance => this.balance$.next(balance))

    this.calculateOffer()
    // $ctrl.initialized = true
    // $scope.$watch('$ctrl.matchedOffer.costs', this.calculateOffer)
  }

  protected onDocsCountryChange() {
    // Proforma document is mandatory for deals with documents country = Colombia
    if (this.moForm.controls.docsCountryCode.value === 'CO') {
      this.moForm.controls.proformaNeeded.setValue(true)
    }
  }

  protected async onOriginLocationChange(locationId: DeepReadonly<LocationObject>) {
    const ctrl = this.moForm.controls.supplierIncotermLocationId
    ctrl.setValue(locationId.location_id)
    ctrl.markAsDirty()
    await this.refreshSecondaryCosts()
    await this.calculateOffer()
  }

  protected onOriginTypeChange(change: MatRadioChange) {
    const ctrl = this.moForm.controls.originPortId;
    if (change.value) {
      ctrl.addValidators(Validators.required);
    } else {
      ctrl.clearValidators();
    }
    ctrl.updateValueAndValidity();
    this.pickupPortElement.checkIfRequired();
  }

  protected onDestTypeChange(change: MatRadioChange) {
    const ctrl = this.moForm.controls.destPortId;
    if (change.value) {
      ctrl.addValidators(Validators.required);
    } else {
      ctrl.clearValidators();
    }
    ctrl.updateValueAndValidity();
    this.destPortElement.checkIfRequired();
  }

  protected async onDestLocationChange(locationId: DeepReadonly<LocationObject>) {
    const ctrl = this.moForm.controls.buyerIncotermLocationId
    ctrl.setValue(locationId.location_id)
    ctrl.markAsDirty()
    await this.refreshSecondaryCosts()
    await this.calculateOffer()
  }

  protected async onProductChange() {
    await this.refreshSecondaryCosts()
    await this.calculateOffer()
  }

  protected onSupplierMeasureChange(nextMeasure: Measure) {
    const { supplierMeasureId: prevMeasure, supplierEstWeight: prevWeight } = this.moForm.getRawValue()
    const nextWeight = round(this.Measures.convert(prevWeight, prevMeasure, nextMeasure), 2)
    this.moForm.patchValue({ supplierEstWeight: nextWeight || prevWeight })
    this.onSupplierWeightChange()
  }

  protected onBuyerMeasureChange(nextMeasure: Measure) {
    const { buyerMeasureId: prevMeasure, buyerEstWeight: prevWeight } = this.moForm.getRawValue()
    const nextWeight = round(this.Measures.convert(prevWeight, prevMeasure, nextMeasure), 2)
    this.moForm.patchValue({ buyerEstWeight: nextWeight || prevWeight })
    this.onBuyerWeightChange()
  }

  protected onSupplierWeightChange() {
    const { supplierEstWeight, supplierMeasureId, buyerMeasureId } = this.moForm.getRawValue()
    const buyerEstWeight = round(this.Measures.convert(supplierEstWeight, supplierMeasureId, buyerMeasureId), 2)
    this.moForm.controls.buyerEstWeight.setValue(buyerEstWeight)
    this.moForm.controls.buyerEstWeight.markAsDirty()
    this.calculateOffer() // TODO: schedule
  }

  protected onBuyerWeightChange() {
    this.calculateOffer() // TODO: schedule
  }

  protected onPriceChange() {
    this.calculateOffer() // TODO: schedule
  }

  protected onCurrencyChange() {
    this.calculateOffer()
  }

  // $ctrl.onSupplierLiabilityChange = onSupplierLiabilityChange
  // $ctrl.onBuyerTermDateChange = onBuyerTermDateChange
  // $ctrl.onCollectionDateChange = onCollectionDateChange

  // function $onInit() {
  //   onProductChange({ initPhase: true })
  //   initPartiesInfo()
  // }

  // function initPartiesInfo() {
  //   // data fixes
  //   $ctrl.matchedOffer.bid.attributes.trader_user_id = $ctrl.matchedOffer.bid.attributes.trader_user_id
  //     || $ctrl.matchedOffer.offer.attributes.trader_user_id_supplier
  //   $ctrl.matchedOffer.offer.attributes.supplier_user_ids = $ctrl.matchedOffer.offer.attributes.supplier_user_ids
  //     || compact([get($ctrl.supplierUsers[0], 'user_id')])
  //   // $ctrl.matchedOffer.bid.attributes.buyer_user_ids = $ctrl.matchedOffer.bid.attributes.buyer_user_ids
  //   //   || compact([get($ctrl.buyerUsers[0], 'user_id')])
  //   const valid = buyer_user_ids => intersection(buyer_user_ids, _map($ctrl.buyerUsers, 'user_id')).length
  //   if (!valid($ctrl.matchedOffer.bid.attributes.buyer_user_ids)) {
  //     const { contact, bwiManager } = Users.getDefaultContacts($ctrl.buyerUsers, $ctrl.users)
  //     const buyer_user_id = Accounts.isBwiInventory(buyer) ? get(bwiManager, 'user_id') : get(contact, 'user_id')
  //     $ctrl.matchedOffer.bid.attributes.buyer_user_ids = buyer_user_id ? [buyer_user_id] : []
  //   }
  // }

  keepTermDateInSync() {
    return this.accounts$.pipe(take(1), switchMap(accounts => {
      const calculateTerms = (
        changedField: /* keyof SegmentFormValue |  */keyof MatchedOfferFormValue,
        supplierPaymentTerms: DealPaymentTerms,
        buyerPaymentTerms: DealPaymentTerms,
      ) => {
        const buyer = accounts[this.matchedOffer.bid.account]
        const changed = this.calculateTermDate({ changedField, supplierPaymentTerms, buyerPaymentTerms, buyer })
        return changed ? this.calculateOffer() : of(false)
      }
      // update collection date on buyer term date change
      const calculateCollectionDate = (buyerTermDate: number, buyerPaymentTerms: DealPaymentTerms) => {
        if (!buyerTermDate && !buyerPaymentTerms) return
        const buyer = accounts[this.matchedOffer.bid.account]
        const epochDay = 86400
        const avgDelay = buyer?.attributes?.credit_info?.avg_days_to_due_date || 0
        const days = buyerPaymentTerms.days
        this.moForm.patchValue({
          collectionDate: buyerTermDate + (days + avgDelay) * epochDay,
        })
      }

      return combineLatest([
        replayForm(this.moForm.controls.shipmentDatesTo).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          withLatestFrom(this.supplierPaymentTerms$), withLatestFrom(this.buyerPaymentTerms$),
          switchMap(([[, spt], bpt]) => calculateTerms('shipmentDatesTo', spt, bpt))),
        replayForm(this.moForm.controls.deliveryDatesTo).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          withLatestFrom(this.supplierPaymentTerms$), withLatestFrom(this.buyerPaymentTerms$),
          switchMap(([[, spt], bpt]) => calculateTerms('deliveryDatesTo', spt, bpt))),
        // this.supplierPaymentTerms$.pipe(
        //   distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
        //   withLatestFrom(this.buyerPaymentTerms$), switchMap(([spt, bpt]) => calculateTerms('supplierPaymentTerms', spt, bpt))),
        // this.buyerPaymentTerms$.pipe(
        //   distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
        //   withLatestFrom(this.supplierPaymentTerms$), switchMap(([bpt, spt]) => calculateTerms('buyerPaymentTerms', spt, bpt))),
        replayForm(this.moForm.controls.buyerTermDate).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          withLatestFrom(this.buyerPaymentTerms$), tap(([buyerTermDate, bpt]) => calculateCollectionDate(buyerTermDate, bpt))),
        replayForm(this.moForm.controls.supplierLiabilityDate).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          switchMap(() => this.calculateOffer())),
        replayForm(this.moForm.controls.collectionDate).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          switchMap(() => this.calculateOffer())),
      ])
    }))
  }

  protected onShipmentDatesChange() {
    combineLatest([this.buyer$, this.buyerPaymentTerms$, this.supplierPaymentTerms$]).pipe(take(1))
    .subscribe(([buyer, buyerPaymentTerms, supplierPaymentTerms]) => {
      if (this.calculateTermDate({ changedField: 'shipmentDate', buyer, buyerPaymentTerms, supplierPaymentTerms })) {
        this.calculateOffer()
      }
    })
  }

  protected onDeliveryDatesChange() {
    combineLatest([this.buyer$, this.buyerPaymentTerms$, this.supplierPaymentTerms$]).pipe(take(1))
    .subscribe(([buyer, buyerPaymentTerms, supplierPaymentTerms]) => {
      if (this.calculateTermDate({ changedField: 'deliveryDate', buyer, buyerPaymentTerms, supplierPaymentTerms })) {
        this.calculateOffer()
      }
    })
  }

  protected onSupplierLiabilityChange() {
    this.calculateOffer()
  }

  protected onBuyerTermDateChange(termDate: number) {
    combineLatest([this.buyer$, this.buyerPaymentTerms$]).pipe(take(1)).subscribe(([buyer, buyerPaymentTerms]) => {
      const endDate = this.Finance.getEndDate(termDate, buyerPaymentTerms)
      const epochDay = 86400
      const avgDelay = buyer.attributes?.credit_info?.avg_days_to_due_date || 0
      this.moForm.patchValue({
        buyerTermDate: termDate || undefined,
        collectionDate: endDate + avgDelay * epochDay || undefined,
      })
      this.calculateOffer()
    })
  }

  protected onCollectionDateChange() {
    this.calculateOffer()
  }

  private async refreshSecondaryCosts() {
    await this.MatchedOfferForm.refreshSecondaryCosts(
      this.matchedOffer.offer.account,
      this.matchedOffer.bid.account,
      this.moForm,
      this.costsForm,
    )
  }


  private async calculateOffer() {
    const data = await this.Finance.calculateMatchedOfferForm(this.matchedOffer, this.moForm, this.costsForm)
    this.totals$.next(data.deal.attributes.estimated)
  }

  /**
   * Calculate deal dates
   *
   * Ant Liab. Date (Supplier Side) = last date of the Supplier date range + Supplier payment Terms
   * Ant Liab. Date (Buyer Side) = Ant Lib Date (Supplier Side)
   * Ant Term Date = last date of Buyer date range
   * Ant Collection Date = Ant Term Date + Buyer Payment Terms
   *
   * TODO: move closer to calculations or dealview
   * NOTE: we update finance_term in other deal financials
   *
   * @private
   */
  private calculateTermDate({
    changedField,
    supplierPaymentTerms,
    buyerPaymentTerms,
    buyer,
  }: {
    changedField?: string
    supplierPaymentTerms: DeepReadonly<DealPaymentTerms>
    buyerPaymentTerms: DeepReadonly<DealPaymentTerms>
    buyer: DeepReadonly<AccountObject>
  }) {
    let changed = false
    const mo = this.moForm.getRawValue()
    const created = this.matchedOffer.created

    if (!changedField || this.Finance.isFinanceDate(changedField, supplierPaymentTerms)) {
      const { termDate, endDate } = this.Finance.getTermDates({
        created,
        shipmentDate: mo.shipmentDatesTo,
        deliveryDate: mo.deliveryDatesTo,
        paymentTerms: supplierPaymentTerms,
      })
      this.moForm.patchValue({
        supplierTermDate: termDate || undefined,
        supplierLiabilityDate: endDate || undefined,
      })
      changed = true
    }

    if (!changedField || this.Finance.isFinanceDate(changedField, buyerPaymentTerms)) {
      const { termDate, endDate } = this.Finance.getTermDates({
        created,
        shipmentDate: mo.shipmentDatesTo,
        deliveryDate: mo.deliveryDatesTo,
        paymentTerms: buyerPaymentTerms,
      })
      const epochDay = 86400
      const avgDelay = buyer.attributes?.credit_info?.avg_days_to_due_date || 0
      this.moForm.patchValue({
        buyerTermDate: termDate || undefined,
        collectionDate: endDate + avgDelay * epochDay || undefined,
      })
      changed = true
    }

    return changed
  }


  private hasSupplierInstructions(mo: MatchedOfferFormValue) {
    return mo.supplierInstructions?.find(note => {
      const companies = isArray(note.attributes?.company) ? note.attributes?.company : [note.attributes?.company]
      return note.attributes &&
        includes(companies.map(parseFloat), this.matchedOffer.offer.account) &&
        note.attributes.category === SPECIAL_INSTRUCTIONS
    })
  }

  private hasBuyerInstructions(mo: MatchedOfferFormValue) {
    return mo.buyerInstructions?.find(note => {
      const companies = isArray(note.attributes?.company) ? note.attributes?.company : [note.attributes?.company]
      return note.attributes &&
        includes(companies.map(parseFloat), this.matchedOffer.bid.account) &&
        note.attributes.category === SPECIAL_INSTRUCTIONS
    })
  }

  protected async addSupplierSpecialInstructions() {
    const existing = this.hasSupplierInstructions(this.moForm.getRawValue())
    const title = existing ? 'Update Supplier Special Instructions' : 'Create Supplier Special Instructions'
    const note = this.moForm.controls.supplierInstructions.value?.[0]
    const partyId = this.matchedOffer.offer.account.toString()

    const updatedNote = await this.NoteForm.showMatchedOfferNote(title, partyId, note)
    if (!updatedNote) return

    this.moForm.controls.supplierInstructions.setValue([{
      ...updatedNote,
      attributes: {
        company: [this.matchedOffer.offer.account.toString()],
        category: SPECIAL_INSTRUCTIONS,
      },
    }])
  }

  protected async addBuyerSpecialInstructions() {
    const existing = this.hasBuyerInstructions(this.moForm.getRawValue())
    const title = existing ? 'Update Buyer Special Instructions' : 'Create Buyer Special Instructions'
    const note = this.moForm.controls.buyerInstructions.value?.[0]
    const partyId = this.matchedOffer.bid.account.toString()

    const updatedNote = await this.NoteForm.showMatchedOfferNote(title, partyId, note)
    if (!updatedNote) return

    this.moForm.controls.buyerInstructions.setValue([{
      ...updatedNote,
      attributes: {
        company: [this.matchedOffer.bid.account.toString()],
        category: SPECIAL_INSTRUCTIONS,
      },
    }])
  }
}
