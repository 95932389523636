import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { AccountApiService } from './account'
import { AESApiService } from './aes'
import { ARReportApiService } from './ar-report'
import { AuditApiService } from './audit'
import { AuthApiService } from './auth'
import { AuthInterceptorService } from './auth-interceptor.service'
import { BidApiService } from './bid'
import { BookingsApiService } from './booking'
import { BusinessTypeApiService } from './business-type'
import { ConsigneesApiService } from './consignees'
import { CostApiService } from './cost/cost'
import { SecondaryCostApiService } from './cost/secondary-cost'
import { CreditNoteApiService } from './credit-note'
import { CreditPoolApiService } from './credit-pool'
import { DealApiService } from './deal'
import { DepartmentApiService } from './department'
import { DocumentApiService } from './document-lib/document'
import { DocumentOverrideApiService } from './document-lib/document-override'
import { DocumentSetApiService } from './document-lib/document-set'
import { EstimateApiService } from './estimate'
import { ExportApiService } from './export'
import { FileApiService, FileUploaderService } from './file'
import { ForexApiService } from './forex'
import { ImportApiService } from './import'
import { InvoiceApiService } from './invoice'
import { LogisticNoteApiService } from './logistic-note'
import { MacropointApiService } from './macropoint'
import { MatchedOfferApiService } from './matched-offer'
import { MessageApiService } from './message'
import { BankAccountApiService } from './metadata/bank-account'
import { CountryApiService } from './metadata/country'
import { CurrencyApiService } from './metadata/currency'
import { CustomTagApiService } from './metadata/custom-tag'
import { PaymentMethodApiService } from './metadata/payment-method'
import { PaymentReferenceApiService } from './metadata/payment-reference'
import { PricingTermApiService } from './metadata/pricing-term'
import { NoteApiService } from './note'
import { OfferApiService } from './offer'
import { OperationsApiService } from './operations'
import { PackageTrackingApiService } from './package-tracking'
import { ProductCategoryApiService } from './product/category'
import { ItemTypeApiService } from './product/item-type'
import { MeasureApiService } from './product/measure'
import { ProductPackagingApiService } from './product/packaging'
import { ProductApiService } from './product/product'
import { ProductSpecificationApiService } from './product/specification'
import { ProductTypeApiService } from './product/type'
import { WeightTypeApiService } from './product/weight-type'
import { ProductWrappingApiService } from './product/wrapping'
import { ReceiptApiService } from './receipt'
import { CarrierApiService } from './shipment-routing/carrier'
import { LocationApiService } from './shipment-routing/location'
import { RoutesApiService } from './shipment-routing/routes'
import { SegmentApiService } from './shipment-routing/segment'
import { ShipmentApiService } from './shipment-routing/shipment'
import { ShipmentRateApiService } from './shipment-routing/shipment-rate'
import { TrackingProviderApiService } from './shipment-routing/tracking-provider'
import { TransitTimeApiService } from './shipment-routing/transit-time'
import { UnlocodeApiService } from './shipment-routing/unlocode'
import { SupplierOfferApiService } from './supplier-offer'
import { TableViewApiService } from './table-view'
import { TemplateApiService } from './template'
import { TriggerApiService } from './trigger'
import { UserApiService } from './user'
import { ValidateApiService } from './validate'
import { VendorCreditApiService } from './vendor-credit'

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    AccountApiService,
    AuditApiService,
    AuthApiService,
    BidApiService,
    BusinessTypeApiService,
    CreditNoteApiService,
    CreditPoolApiService,
    DealApiService,
    DepartmentApiService,
    EstimateApiService,
    FileApiService,
    FileUploaderService,
    ForexApiService,
    ImportApiService,
    InvoiceApiService,
    LogisticNoteApiService,
    MacropointApiService,
    MatchedOfferApiService,
    MessageApiService,
    NoteApiService,
    OfferApiService,
    PackageTrackingApiService,
    ReceiptApiService,
    SupplierOfferApiService,
    TableViewApiService,
    TemplateApiService,
    MessageApiService,
    TriggerApiService,
    MessageApiService,
    UserApiService,
    VendorCreditApiService,
    // CostTypeApiService,
    CostApiService,
    // FinancialTermApiService,
    SecondaryCostApiService,
    DocumentOverrideApiService,
    DocumentSetApiService,
    DocumentApiService,
    BankAccountApiService,
    CountryApiService,
    CurrencyApiService,
    CustomTagApiService,
    PaymentMethodApiService,
    PaymentReferenceApiService,
    PricingTermApiService,
    ProductCategoryApiService,
    ItemTypeApiService,
    MeasureApiService,
    ProductPackagingApiService,
    ProductApiService,
    ProductSpecificationApiService,
    ProductTypeApiService,
    WeightTypeApiService,
    ProductWrappingApiService,
    ARReportApiService,
    CarrierApiService,
    LocationApiService,
    RoutesApiService,
    SegmentApiService,
    ShipmentRateApiService,
    ShipmentApiService,
    TrackingProviderApiService,
    TransitTimeApiService,
    UnlocodeApiService,
    ConsigneesApiService,
    BookingsApiService,
    OperationsApiService,
    AESApiService,
    ExportApiService,
    ValidateApiService,
    provideHttpClient(withInterceptorsFromDi())
  ],
})
export class ApiModule { }
